import React, { useRef, useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../recoil/atoms'
import { dialSessions } from '../recoil/selectors'
import { useLocalStorage } from '../hooks/hooks'
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/material/styles';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import MaterialTable, { MTableBodyRow } from "@material-table/core"
import SessionTableDetails from '../components/SessionTableDetails';
import SessionsSubscription from '../recoil/SessionsSubscription';
import { withSuspense } from '../hooks/suspense'
import DialModal from '../components/DialModal';
import { useOktaAuth } from '@okta/okta-react';
import { updateSessionPromise } from '../services/sessionsService'
import moment from 'moment'

const PREFIX = 'DialSessionsPage';

const classes = {
  sessions: `${PREFIX}-sessions`,
  page: `${PREFIX}-page`
};

const Root = styled('div')({
  [`& .${classes.sessions}`]: {
    marginBottom: '1rem',
    width: '100%'
  },
  padding: '1em'
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const allowedColumns = [
  'id', 'scoreBase', 'dialStyle', 'sliderKnob', 'sliderKnobColor', 'gaugeStyle', 'dialSnap', 'dialMotion', 'numberOfMeters', 'includeTuneOut', 'internalVideoName', 'videoMarkers', 'chartVideoStart', 'chartVideoEnd', 'dialChartSegments', 'chartConfig'
]

const sessionTime = (time) => {
  const localTimezone = moment.tz.guess()
  return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
}

const DialSessionsPage = React.memo(({ ...props }) => {

  // const [sessions, setSessions] = useRecoilState(sessionState);
  const sessions = useRecoilValue(dialSessions);
  const user = useRecoilValue(currentUser);
  const [tableSessions, setTableSessions] = useState(undefined)
  const [sessionSelected, setSessionSelected] = useLocalStorage('dialSessionSelected', null)//useState();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState({});
  const [sessionDialogOpen, setSessionDialogOpen] = useLocalStorage('dialSessionDialogState', false) //useState(false);
  // const [error, setError] = useState();
  // const [errorOpen, setErrorOpen] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // const [tableFilters, setTableFilters] = useLocalStorage(`session${filter}tablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`dialsessionstablesort`, {})
  // const [openRow, setOpenRow] = useLocalStorage(`dialsessionsopenrow`, {})
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  const tableRef = useRef()
  // const openRef = useRef(openRow)

  // useEffect(() => {
  //   openRef.current = openRow
  // }, [openRow])

  useEffect(() => {
    setTableSessions(sessions ? sessions.map(o => {
      const s = { ...o }

      // OPEN PREVIOUSLY FOCUSED ROW (IF RELOADED)
      // if (tableRef.current) {
      //   const { detailPanel } = tableRef.current.props;
      //   let handleShowDetailPanel = detailPanel;

      //   if (typeof detailPanel === 'object') {
      //     handleShowDetailPanel = detailPanel[0].render
      //   }

      //   s.tableData = {
      //     showDetailPanel: `${s.id}` === `${openRef.current.id}` && handleShowDetailPanel
      //   }
      // }
      return s
    }) : undefined)
  }, [sessions])

  const dismissBtn = useCallback((id) => {
    return <>
      <IconButton
        aria-label="close"
        color="inherit"
        className={classes.close}
        onClick={() => closeSnackbar(id)}
      >
        <CloseIcon />
      </IconButton>
    </>
  }, []);

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const showSessionGraph = useCallback((event, row) => {
    // Set row data to selected session state
    navigate(`/session/${row.id}`)
  }, [navigate])

  const submitSession = useCallback((event, submitData) => {
    setLoading(true)
    const data = difference(sessionSelected, submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      const isDialSession = data.hasOwnProperty('isDialSession') ? data.isDialSession : sessionSelected?.isDialSession

      if (sessionSelected) data.id = sessionSelected.id
      for (let i in data) {
        // if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
        if (!allowedColumns.includes(i) || (typeof data[i] !== 'boolean' && !data[i])) delete data[i]
      }

      if (data.tableData) delete data.tableData

      if (!isDialSession) {
        // data.dialBranding = null
        data.dialStyle = null
        data.dialMotion = null
        data.dialSnap = null
        data.gaugeStyle = null
        data.numberOfMeters = null
        data.scoreBase = null
        data.sliderKnob = null
        data.sliderKnobColor = null
        data.includeTuneOut = false
        data.inactiveReminder = false
        data.inactiveInterval = null
        data.inactiveMessage = null
        data.clientDialViewer = false
      }

      // FOR MYSQL DATA API
      if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
      if (data.dialChartSegments) data.dialChartSegments = JSON.stringify(data.dialChartSegments)
      if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName)
      if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)

      data.lastUpdatedBy = user.email
      data.type = 'Content'
      const token = oktaAuth.getAccessToken()
      updateSessionPromise(data, token).then((res) => {
        setUpdate({})
        setLoading(false)
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Session')
          enqueueSnackbar('Error Saving Session', { preventDuplicate: true, action: dismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      setUpdate({})
      setLoading(false)
    }
    setSessionDialogOpen(false);
    setSessionSelected(null)
  }, [sessionSelected, oktaAuth, setSessionDialogOpen, setSessionSelected, user.email]);

  const editSession = useCallback((event, data) => {
    console.log('data:', data)
    setSessionSelected(data);
    setSessionDialogOpen(true);
  }, [setSessionSelected, setSessionDialogOpen, update])

  const handleCloseSessionDialog = useCallback(() => {
    setSessionDialogOpen(false);
    setSessionSelected(null)
  }, [setSessionSelected, setSessionDialogOpen]);

  return (
    <Root>
      <SessionsSubscription refresh={update} key={`dialsessionssubscription`} />
      {sessionDialogOpen && <DialModal team={user.team} data={sessionSelected} onSubmit={submitSession} open={sessionDialogOpen} handleClose={handleCloseSessionDialog} hasProject={false} />}
      <div className={classes.sessions}>
        <MaterialTable
          key={`dialsessionstable`}
          tableRef={tableRef}
          onOrderChange={(i, order) => setTableSort({
            [i]: order
          })}
          // onFilterChange={(e) => setTableFilters(prev => {
          //   return { ...prev, [filter]: e[0].value }
          // })}
          options={{
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelType: 'single',
            pageSize: 20
          }}
          columns={[
            { title: "Session Name", field: "sessionName", defaultSort: tableSort[0] },
            { title: "Scheduled Date/Time", field: "scheduledStartTime", type: "datetime", defaultSort: Object.keys(tableSort).length ? tableSort[1] : 'desc', render: row => <span>{sessionTime(row.scheduledStartTime)}</span> },
            { title: "Video Title", field: "videoTitle", defaultSort: tableSort[2] },
            { title: "Session Start Time", field: "sessionStartTime", type: "datetime", defaultSort: tableSort[3], render: row => <span>{row.sessionStartTime ? sessionTime(row.sessionStartTime) : 'N/A'}</span> }
          ]}
          isLoading={loading || !tableSessions}
          data={tableSessions || []} //MATERIAL TABLE MUTATES PROPS :(
          title="Dial Sessions"
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: editSession,
            },
            (rowData) => {
              return {
                icon: ShowChartIcon,
                tooltip: 'View Graph',
                hidden: (!rowData.isDialSession),
                onClick: showSessionGraph,
                disabled: (!rowData.started)
              }
            }
          ]
          }
          components={{
            // Action: props => {
            //   const action = typeof props.action === "function" ? props.action(props.data) : props.action
            //   return (
            //     <MTableActionLoading {...props} />
            //   )
            // },
            // Actions: props => {
            //   return (
            //     <div className={classes.actionRow}><MTableActions {...props} /></div>
            //   )
            // },
            // Row: props => {
            //   return (
            //     <MTableBodyRow {...props}
            //       onRowClick={(e, row, toggleDetailPanel) => {
            //         props.onToggleDetailPanel(props.path, typeof props.detailPanel === 'object' ? props.detailPanel[0].render : props.detailPanel)
            //         e.stopPropagation();
            //         // openRef.current = openRef.current.id === row.id ? {} : row
            //         setOpenRow(prev => {
            //           const selected = (prev.id === row.id) ? {} : { id: row.id }
            //           return selected
            //         })
            //       }} />
            //   )
            // }
          }}
          detailPanel={[{
            // disabled: true,
            render: ({ rowData }) => <SessionTableDetails {...rowData} />
          }]}
        // detailPanel={rowData => <SessionTableDetails controlVideo={handleStartVideo} {...rowData} />}
        />
      </div>
      {/* <Snackbar
        // key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        onExited={handleErrorClear}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
    </Root>
  );
})

export default withSuspense(DialSessionsPage)